
@tailwind base;
@tailwind components;
@tailwind utilities;

.su-header {
    @apply p-2 border-b border-gray-100 bg-white shadow-sm;
}

.su-container {
    @apply container mx-auto px-4 max-w-[1180px];
}

.btn {
    @apply bg-su-blue rounded-lg px-4 py-[0.8125rem] text-white shadow-md border border-su-blue hover:border-su-blue hover:border hover:bg-white hover:text-black justify-center flex gap-2 items-center cursor-pointer;

    &.btn--large {
        @apply min-w-[100px];
    }

    &.btn--small {
        @apply md:flex-row flex-row-reverse;
        padding: .375rem .75rem;
        min-width: 8.5rem;
    }

    &.btn--outline {
        @apply bg-white text-black border border-su-blue hover:bg-su-blue hover:text-white
    }
}

.heading {
    font-size: 2.625rem;
    line-height: 1.2;
}

.listing-card {
    @apply border-gray-400 rounded-sm py-4 px-5 flex md:gap-3 items-center flex-col md:flex-row gap-4;
}

.text-link {
    @apply hover:underline text-gray-400 text-sm
}

.footer {
    @apply bg-white py-10 border border-gray-300;
}

.footer-heading {
    @apply text-base whitespace-nowrap mb-4;
}

.lang-switch-dropdown {
    @apply absolute w-full border border-su-blue;

    border-radius: 0 0 2rem 2rem;

    li {
        text-align: center;
        padding: .25rem;
        background-color: #fff;

        &:hover {
            @apply bg-su-blue text-white cursor-pointer;
        }

        &:last-child {
            border-radius: 0 0 2rem 2rem;
        }
    }
}

.dropdown-open {
    border-radius: 1.6rem 1.6rem 0 0;
    border-bottom: 0;

    & + ul {
        border-top: 0;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: "Lato",  sans-serif; }


.bg-fcr-blue {
    background-color: #00ABC2;
}

.text-fcr-blue {
    color: #00ABC2;
}

// form styling
.mat-accent {
    --mat-option-selected-state-label-text-color: #00006e;
    --mat-option-selected-state-layer-color: #e0e0ff;
    --mdc-circular-progress-active-indicator-color: #00ABC2;
    --mat-full-pseudo-checkbox-selected-icon-color: #00ABC2;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00ABC2;
    --mat-stepper-header-selected-state-icon-background-color: #00ABC2;
    --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
    --mat-stepper-header-edit-state-icon-background-color: #00ABC2;
    --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
}

.mat-accent.mat-mdc-slide-toggle {
    --mdc-switch-selected-focus-state-layer-color: #00ABC2;
    --mdc-switch-selected-handle-color: #ffffff;
    --mdc-switch-selected-hover-state-layer-color: #00ABC2;
    --mdc-switch-selected-pressed-state-layer-color: #00ABC2;
    --mdc-switch-selected-focus-handle-color: #e0e0ff;
    --mdc-switch-selected-hover-handle-color: #e0e0ff;
    --mdc-switch-selected-pressed-handle-color: #e0e0ff;
    --mdc-switch-selected-focus-track-color: #00ABC2;
    --mdc-switch-selected-hover-track-color: #00ABC2;
    --mdc-switch-selected-pressed-track-color: #00ABC2;
    --mdc-switch-selected-track-color: #00ABC2;
    --mdc-switch-selected-icon-color: #00006e;
    --mat-switch-track-outline-color: #74777f;
    --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
    --mat-switch-label-text-color: #1a1b1f;
}

.mat-primary {
    --mat-stepper-header-selected-state-icon-background-color: #00ABC2;
    --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
    --mat-stepper-header-edit-state-icon-background-color: #00ABC2;
    --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
}

:root {
    --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-form-field-hint-wrapper, 
.mat-mdc-form-field-error-wrapper {
    top: .5rem !important;
    left: unset !important;
    min-width: 32rem;
    flex-flow: row-reverse;
    color: #606060;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translate(.5rem, -3.75rem) scale(0.75) !important;
}
.opening-hours__time {
    .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        transform: translate(0, -3rem) scale(0.75) !important;
    }
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right: 0 !important;
    border-top: 1px solid #73777F;
}

.mat-focused {
    .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-notched-outline .mdc-notched-outline__trailing
     {
        border-color: #00ABC2 !important;

        &:hover {
            border-top: 1px solid #00ABC2 !important;
        }
    }

    &.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
        border-top: 2px solid #00ABC2 !important;
        
    }
}

.mat-form-field-invalid {
    &.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
        border-top: 1px solid red !important;
        
        &:hover {
            border-top: 1px solid #73777F !important;
        }
    }

    &.mat-focused {
        .mdc-notched-outline .mdc-notched-outline__leading,
        .mdc-notched-outline .mdc-notched-outline__notch,
        .mdc-notched-outline .mdc-notched-outline__trailing
         {
            border-color: #00ABC2 !important;
    
            &:hover {
                border-top: 1px solid #00ABC2 !important;
            }
        }
    
        &.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
            border-top: 2px solid #00ABC2 !important;
            
        }
    }
}

.logo {
    @apply w-[6.25rem] h-[6.25rem];
}

.logo-text {
    height: 2rem;
    width: 5rem;
}

.message {
    margin-top: 1rem;
    padding: .5rem .75rem;
    border-radius: .25rem;
    
    &.message--error {
        border: 1px solid #f9a5a5;
        background-color: #ffe8e8;
        color: #c61818;
    }
}

@media (max-width:767px) {
    .logo {
        height: 3rem;
        width: 3rem;
    }
    
    .logo-text {
        height: 0.75rem;
    }

    #language-switch {
        &>div {
            padding: 0.5rem;
            min-width: 4rem;
        }
    }

    .login-btn {
        @apply p-2;
    }
}

.active-dashboard-link svg path {
    fill: #00ABC2;
}